:root {
  --right-sidebar-width: 0px;
}
.right-sidebar-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: var(--right-sidebar-width);
  border: 1px groove #bbbec55e;
  transition: all 0.5s ease;
  z-index: 1200;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  overflow-y: scroll;
}
.color-input-2 {
  padding-left: 87% !important;
  height: 3.3em !important;
  cursor: pointer;
}

.survey-form-fields {
  position: relative;
}
.survey-form-fields-menu {
  display: none;
  width: 4rem;
  height: 1.5rem;
  background-color: red !important;
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  right: 0;
  top: -1.5rem;
}
.survey-form-fields:hover .survey-form-fields-menu {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  display: block;
}
.survey-form-fields:hover {
  border: 1px solid black;
}
/* .color-input {
  padding-left: 85% !important;
  height: 2.7rem !important;
  cursor: pointer;
} */

.survey-form-logo {
  border: 1px solid #ced4da;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
}
/* .survey-form-fields-active {
  border: 1px solid black;
  cursor: pointer;
} */
.right-sidebar-menu .toogle_btn .close-icon {
  font-size: 22px;
  margin-left: 4%;
  margin-top: 5%;
}

.right-sidebar-menu .toogle_btn .close-icon:hover {
  font-size: 24px;
  transform: rotate(180deg);
  transition: all 1s;
}

.right-sidebar-menu .toogle_btn .close-icon:not(:hover) {
  transform: rotate(-180deg);
  transition: all 1s;
}
