.buy-modal {
  padding: 8px !important;
  width: auto;
  height: auto;
  background: linear-gradient(180deg, #dcf9e0 0%, #ffffff 30.21%) !important;
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01),
    0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09),
    0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.title {
  font-weight: 900;
  font-size: 20px;
  text-align: center;
  color: #2b2b2f;
}
.title-main {
  padding-left: 10px;
  font-weight: 700;
  font-size: 17px;
  color: #2b2b2f;
}

.benefits {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.benefits > span {
  font-size: 15px;
  color: #2b2b2f;
  font-weight: 700;
}
ul {
  padding-left: 0 !important;
}

.benefits ul {
  display: flex;
  flex-direction: column;
}

.benefits ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.benefits ul li span {
  font-weight: 600;
  font-size: 12px;
  color: #5f5d6b;
}

.modal--footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #ebebec;
}

.price {
  position: relative;
  font-size: 32px;
  color: #2b2b2f;
  font-weight: 900;
}

.price sup {
  font-size: 13px;
}

.price sub {
  width: fit-content;
  position: absolute;
  font-size: 11px;
  color: #5f5d6b;
  bottom: 5px;
  display: inline-block;
}

.upgrade-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  background: #474e76;
  margin-left: 1.5rem;
  box-shadow: 0px 0.5px 0.5px #f4b1b1, 0px 1px 0.5px rgba(239, 239, 239, 0.5);
  border-radius: 7px;
  border: 0;
  outline: none;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.upgrade-btn:hover {
  background-color: #168fe0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
