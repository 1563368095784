.color-input {
  /* margin-left: 80% !important; */
  height: 2.4375em !important;
  cursor: pointer;
}
.slides {
  background-color: #9fceea36 !important;
  margin-bottom: 10px;
  padding: 10px 15px;
  height: auto;
  min-height: 150px;
  border-radius: 10px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
