.img_btn{
    padding: 10px;
    background-color: rgb(239, 239, 239);
    border: 0px;
    border-radius: 5px;
}
.img_text{
    font-size: 11px;
    font-weight: 500;
    max-width: 250px;
    color: black;
}
.img_adj{
    text-align: right !important;
}
.icon_u{
    width: 2em;
    height: 1.5em;
    margin-bottom: -4px;
    margin-right: 2px;
}
.form-control{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.apps-card{
    overflow: unset !important;
}

