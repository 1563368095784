:root{
    --logo-color: #1A202C;
    --button-color:#1A202C;
    --text-color:hwb(217 82% 15%);
    --sidnav-font-family:"Roboto","Helvetica","Arial",sans-serif;
    --card-font-family:"Roboto","Helvetica","Arial",sans-serif;
    --card-Bg:#fff;
    --theme-Bg:#fff;
    --snapshot-des-text:#90809A;
    --snapshot-title:#334666;
    --snapshot-des-heading:#7B809A;
    --SnapshotButton:#7B809A;
    --PurchaseButton:#7B809A;
    --cards-color:linear-gradient(195deg, #49a3f1, #1A73E8);
    --cards-box-shadow:0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(0 187 212 / 40%);


    }
   